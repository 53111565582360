import '../stylesheets/application.css';
import TwoColumnLayout from './TwoColumnLayout'
import {useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {SendBillerForm} from "../api/BillerApi";
import {useAuth} from "../Auth";
/* remittanceAddresses = [
    {
        "zip": "40290",
        "city": "LOUISVILLE",
        "state": "KY",
        "address1": "PO BOX 9001557"
    },
    {
        "zip": "32896",
        "city": "ORLANDO",
        "state": "FL",
        "address1": "PO BOX 960061"
    }
]*/

export default function SelectAddressPage() {
    let navigate = useNavigate();
    const location = useLocation();
    const initialFormData = location.state.formData || {}
    const remittanceAddresses = location.state.remittanceAddresses || []
    const biller = location.state.biller || {name: "<biller is not set>", token: 'n/a'}
    const [formData] = useState(initialFormData);
    const [loaderVisible, setLoaderVisibility] = useState(false)
    const [filteredList, setFilteredList] = useState(remittanceAddresses)
    const [pageError, setPageError] = useState()
    const auth = useAuth();
    const accessToken = auth.user?.accessToken
    const billerToken = biller.token

    const handleFilter = e => {
        const res = remittanceAddresses.filter(addr => addr.zip.includes(e.target.value))
        setFilteredList(res)
    }

    const handleSelect = i => {
        setLoaderVisibility(true)
        SendBillerForm({accessToken, billerToken, userData: formData, userSelectedAddress: filteredList[i]}
        ).then(response => {
            const payment = response.data.payment
            navigate("/confirm_an", {state: {formData, biller, payment}})
        }).catch((error) => {
            setPageError(`Error: ${error.response?.data?.error?.message || error.message}`)
        }).finally(() => {
            setLoaderVisibility(false)
        })
    }

    return (
        <TwoColumnLayout loaderVisible={loaderVisible}>
            <h1>Select remittance address</h1>
            {pageError && <div className='form-field-error-text'>{pageError}</div>}
            <p className="intro-text mtm mbm">To continue, select the biller remittance address. You may find this on
                your bill or statement. This is the biller’s address for payments, not your address.</p>

            <div className="search-container zip-filter">
                <form>
                    <div className="search-box">
                        <input placeholder="Enter Zip Code" maxLength="10" tabIndex="" type="search"
                               autoComplete="off" class="zip-filter-input"
                               onChange={handleFilter}/>
                        {formData.notice && <div className="form-field-error-text">{formData.notice}</div>}
                    </div>
                </form>
            </div>
            <ul className="simple-list address-list">
                {filteredList.map(function (a, i) {
                    return (
                        <li className="simple-list-item pvs" onClick={() => handleSelect(i)} key={i}>
                            <div className="clickable">
                                <div>{a.address1}</div>
                                {a.city}, {a.state} {a.zip}
                            </div>
                            <button className="btn-primary small" onClick={() => handleSelect(i)}>Select</button>
                        </li>
                    )
                })
                }
            </ul>
        </TwoColumnLayout>
    );
}
