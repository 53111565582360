import '../stylesheets/application.css';
import TwoColumnLayout from './TwoColumnLayout'
import {useLocation} from "react-router-dom";

export default function NotFoundPage() {
    const location = useLocation()

    return (
        <TwoColumnLayout>
            <h1>404 Not found</h1>
            <div className="mobile-wrapper my-bills-page">
                <p><code>{location.pathname}</code> is not found.</p>
            </div>
        </TwoColumnLayout>
    );
}
