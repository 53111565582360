import '../stylesheets/application.css';
import TwoColumnLayout from './TwoColumnLayout'
import {useLocation} from "react-router-dom";

export default function ThankYouPage() {
    const location = useLocation();
    const {payment} = location.state || {}

    return (
        <TwoColumnLayout>
            <h1>Success</h1>
            <div>Your payment to your home utility provider will typically post within 2 to 4 business days; please check your service provider account for posting.</div>
            <div className="mtm">This payment will be included in your OTC card transaction history.</div>
            <div className="hidden-color mtm">Transaction reference: {payment.confirmation_number}.</div>
        </TwoColumnLayout>
    );
}
