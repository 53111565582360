import '../stylesheets/application.css';
import '../stylesheets/modules/amountize-page.css';
import TwoColumnLayout from './TwoColumnLayout'
import {useNavigate, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuth} from "../Auth";
import {useForm} from "react-hook-form";
import {Amountize, GetBalance, PostPayment} from "../api/BillerApi";
import {maskedString} from "../api/Utilites";
import classnames from "classnames";

const S_GETTING = 1
const S_SUCCESS = 2
const S_ERROR = 3
const PURSES_DICT = {
    'PM8OTCPLUS5': 'Extra Help',
    'PM8OTCPLUS6': 'Medicaid Ben',
    'PM8OTCPLUS7': 'Long Term Care',
    'PW04649PER01': 'Signature HMO OTC',
    'PW04677DNBC01': 'Signature PPO Flex',
    'PW04637PER06': 'IBP OTC PLUS',
    'PW04637PER04': 'IBP OTC',
    'PW04637PER03': 'CNX OTC Plus',
    'PW04637PER02': 'LIP OTC Plus',
    'PW04637PER01': 'CompleteCareOTC Plus',
    'TESTPW0463905C': 'TEST IBP OTC Combo',
    'TESTPW0463902C': 'TESTLIP OTC Combo',
    'TESTPW0463903C': 'TESTCNX OTC Combo',
    'PW04637COMBOIPL': 'IBP OTC Plus Combo',
    'PW04637COMBOLIP': 'LIP OTC Plus Combo',
    'PW04637COMBOCC': 'CompleteCareCombo',
}

export default function AmountizePage() {
    let navigate = useNavigate();
    const location = useLocation();
    const [pageError, setPageError] = useState()
    const {user} = useAuth();
    const accessToken = user?.accessToken
    const {register, handleSubmit, formState: {errors}} = useForm();
    const {biller, formData, payment} = location.state || {}
    const [loaderVisible, setLoaderVisibility] = useState(false)
    const [balance, setBalance] = useState({status: 'getting balance ...', purses: [], state: S_GETTING})
    //Info. purses: [{name: "", balance: "4.0", is_eligible: false}]


    useEffect(() => {
        GetBalance({accessToken, paymentToken: payment.token})
            .then(data => {
                setBalance({status: '', purses: data.data.result.purses, state: S_SUCCESS})
            })
            .catch(error => {
                setPageError()
                setBalance({status: error.response?.data?.error?.message || error.message, purses: [], state: S_ERROR})
            })
            .finally(() => {
            });
    }, [accessToken, payment.token])

    const onSubmit = (amountFormData) => {
        let confirmed = window.confirm(`Please confirm you want to proceed with a $${amountFormData.amount} payment to ${biller.name}`);
        if (!confirmed) {
            return false
        }
        setLoaderVisibility(true)
        Amountize({accessToken, billerToken: biller.token, paymentToken: payment.token, amount: amountFormData.amount})
            .then(response => {
                PostPayment({accessToken, paymentToken: payment.token}
                ).then(response => {
                    if (response.data?.payment?.status === 'processed') {
                        navigate("/thank_you", {state: {biller, formData, payment: response.data.payment}, replace: true})
                    } else {
                        setPageError(`Payment ${payment.token} is not submitted.`)
                    }
                }).catch((error) => {
                    setPageError(error.response?.data?.error?.message || error.message)
                }).finally(() => {
                    setLoaderVisibility(false)
                })
            }).catch((error) => {
            setPageError(error.response?.data?.error?.attributes?.amount || error.response?.data?.error?.message || error.message)
            setLoaderVisibility(false)
        })
    }

    const amountValidations = {
        valueAsNumber: true,
        min: {value: 0.1, message: 'should be greater than zero'},
        max: {value: balance, message: 'The total payment was more than your OTC Card balance'},
        required: {value: true, message: "Can't be blank"},
        validate: {
            numeric: v => !isNaN(v) || 'should be a number',
        },
    }

    return (
        <TwoColumnLayout loaderVisible={loaderVisible}>
            <h1>Payment amount</h1>
            {pageError && <div className='form-field-error-text mbm'><b>There was an error processing your
                payment.</b><br/>{pageError}</div>}
            <div className="mobile-wrapper t6">
                <h3>Summary</h3>
                <div>Biller: {biller.name}</div>
                <div>Account number: {maskedString(formData.account_number)}</div>
                <div>OTC Card number: {maskedString(user.cardNumber)}</div>
                <div>Wallets:</div>

                <div>
                    {balance.state === S_SUCCESS && <span/>}
                    {balance.state === S_GETTING && <span className="otc-balance">{balance.status}</span>}
                    {balance.state === S_ERROR && <span className="form-field-error-text">{balance.status}</span>}
                </div>

                <ul>
                    {balance.purses.map((p, i) => {
                        return (

                            <li key={i} className="purse-row">
                                <span className="">{PURSES_DICT[p.name] || p.name}</span>
                                <span className={classnames('eligible-tag', {'eligible': p.is_eligible, 'not-eligible': !p.is_eligible})}>{p.is_eligible ? 'Eligible!' : 'not eligible'}</span>
                                <span className="balance">${p.balance}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>

            <form className="form mobile-wrapper" onSubmit={handleSubmit(onSubmit)}>
                <div className="outer">
                    <div className="form-field mbm unit-2of3">
                        <label>How much do you want to pay?</label>
                        <div className="line">
                            <span>$</span>
                            <input className="amount-input" type="number" autoComplete="off" step="0.01"
                                   placeholder="0.00" {...register('amount', amountValidations)}/>
                        </div>
                        {errors.amount?.message &&
                            <div className="form-field-error-text">{errors.amount.message}</div>}
                        <div className="mtm">Payment may take 2-4 business days to be posted</div>
                    </div>
                </div>
                <div className="submit">
                    <input type="submit" value="Submit" className="btn-primary"/>
                </div>
            </form>
        </TwoColumnLayout>
    );
}
