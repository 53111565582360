import '../stylesheets/application.css';
import {useNavigate, useLocation} from "react-router-dom";
import {useAuth} from "../Auth";
import {useEffect} from "react";
import {urlSafeBase64Decode} from "../api/Utilites";

export default function BillPage() {
    let location = useLocation();
    let navigate = useNavigate();
    const auth = useAuth();

    const samlError = new URLSearchParams(location.search).get('e');
    const jwt = new URLSearchParams(location.search).get('jwt');
    if (jwt !== null) {
        window.sessionStorage.setItem('sso-jwt', jwt)
    }
    useEffect(() => {
        if (samlError !== null) {
            navigate('/no_auth', {state: {samlError: urlSafeBase64Decode(samlError)}, replace: true})
        } else {
            auth.sign_in(() => navigate("/new_bill", {replace: true}))
        }
    })

    return null;
}
