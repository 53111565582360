import '../stylesheets/application.css';
import TwoColumnLayout from './TwoColumnLayout'
import {Payments} from "../api/BillerApi";
import {useEffect, useState} from "react";
import {useAuth} from "../Auth";

export default function MyBillsPage() {
    const [pageState, setPageState] = useState({loaderVisible: true, error: null, billerAccounts: []})
    const {user} = useAuth();
    const accessToken = user?.accessToken

    useEffect(() => {
        //request data
        Payments({accessToken}
        ).then(data => {
            const rows = data.data.biller_accounts.filter((r) => r.payments.length > 0)
            setPageState(prev => {return { ...prev, loaderVisible: false, billerAccounts: rows }});
        }).catch(error => {
            setPageState(prev => {return { ...prev, loaderVisible: false, error: error.message }});
        })
    }, [accessToken])

    return (
        <TwoColumnLayout loaderVisible={pageState.loaderVisible}>
            <h1>My Bills</h1>
            {pageState.error && <div className='form-field-error-text mbm'>{pageState.error}</div>}
            <ul className="simple-list">
                {pageState.billerAccounts.map(r => {

                    return (<li className="simple-list-item" key={r.token}>
                        <div className="expand-link flexible">
                            <div className="cell" onClick={toggleAction}>
                                <h3 className="pvs">
                                    {r.biller.name}
                                </h3>
                            </div>
                            <div className="cell">
                                <b className="mobile-show">{r.account_number}</b>
                                <b className="mobile-hide">{r.account_number}</b>
                            </div>
                        </div>
                        <ul className="simple-list-item-options inner-options submit">
                            OTC card: {r.otc_card_number}

                            <div className="mtm">
                                {paymentList(r.payments)}
                            </div>
                        </ul>
                    </li>)
                })}
            </ul>
        </TwoColumnLayout>
    );
}

function toggleAction(e) {
    e.preventDefault();
    e.stopPropagation();
    e.target.closest('.simple-list-item').classList.toggle('expanded')
}

function paymentList(rows){
    return <div>
        <ul className="payment-list">
            {rows.map(r => {
                return (
                    <li key={r.token}>
                        <div><div className="label">Time:</div> <div className="value">{r.timestamp}</div></div>
                        <div><div className="label">Amount:</div> <div className="value">{r.amount}</div></div>
                        <div><div className="label">Status:</div> <div className="value">{r.status}</div></div>
                        <div><div className="label">Reference:</div> <div className="value">{r.ref_num}</div></div>
                    </li>
                )
            })}
        </ul>
    </div>
}
