import '../stylesheets/application.css';
import TwoColumnLayout from './TwoColumnLayout'
import {useLocation} from "react-router-dom";
import {useAuth} from "../Auth";
import {ALLOWED_PROGRAM_CODES} from "../api/local_db/AvailableBillers";


export default function NoAuthPage() {
    const location = useLocation();
    const {user} = useAuth();
    const isProgramCodePresent = user.programCode !== undefined && user.programCode !== null
    const isAllowedProgramCode = ALLOWED_PROGRAM_CODES.includes(user.programCode)
    const {from, samlError} = location.state || {from: {pathname: "/"}};
    if (samlError !== null && samlError !== undefined){
        return (
            <TwoColumnLayout>
                <h1 className="error-color">Login Error.</h1>
                <div className="error-color">Login authentication failed. Return to <a href="https://otcnetwork.com">otcnetwork.com</a></div>
                <div className="hidden-color">{samlError}</div>
            </TwoColumnLayout>
        )
    }
    if (isProgramCodePresent && !isAllowedProgramCode) {
        return (
            <TwoColumnLayout>
                <h1 className="error-color">Access Error.</h1>
                <div className="error-color">Account access is unavailable. Return to <a href="https://otcnetwork.com">otcnetwork.com</a></div>
                <div className="hidden-color">Program code '<code>{user.programCode}</code>' is not supported</div>
            </TwoColumnLayout>
        )
    }
    return (
        <TwoColumnLayout>
            <h1 className="error-color">Login Error.</h1>
            <div className="mobile-wrapper">
                <div className="error-color">Please login to your account through <a href="https://otcnetwork.com">otcnetwork.com</a></div>
                <div className="hidden-color">Can't access to page <code>{from.pathname}</code></div>
            </div>
        </TwoColumnLayout>
    );
}
