import '../stylesheets/application.css';
import TwoColumnLayout from './TwoColumnLayout'
import React, {useState} from 'react'
import {useNavigate, useLocation} from "react-router-dom";
import {useAuth} from "../Auth";
import {
    BillerNameByToken,
    GroupedBillersFor,
    ELDERPLAN_PC,
    PC_WITH_3_QUESTION,
    PC_WITH_SPECIAL_ATTESTATION
} from "../api/local_db/AvailableBillers";
import classnames from 'classnames';
import Select from 'react-select';

const OTHER_BILLER_NAME = 'Other...'

export default function NewBillerPage() {
    let navigate = useNavigate();
    const location = useLocation();
    let state = location.state || {}
    const initialBillerToken = state.biller?.token || '';
    const {user} = useAuth();
    const isShowQ3 = PC_WITH_3_QUESTION.includes(user?.programCode)
    const isSpecialAttestations = PC_WITH_SPECIAL_ATTESTATION.includes(user?.programCode)
    const isHealthFirst = !ELDERPLAN_PC.includes(user.programCode)

    let initialFormData = {
        q1: 'no',
        q2: 'no',
        q3: isShowQ3 ? 'no' : 'yes',
        error: null,
        billerToken: initialBillerToken,
    }
    const [formData, setFormData] = useState(initialFormData);

    let initialPageState = {
        hideDiscontinueNotify: false,
    }
    const [pageState, setPageState] = useState(initialPageState);

    let billers = GroupedBillersFor(user.programCode, user.healthPlanCode)
    if (!ELDERPLAN_PC.includes(user.programCode)) {
        billers = billers.concat({value: OTHER_BILLER_NAME, label: OTHER_BILLER_NAME})
    }


    const handleSubmit = e => {
        e.preventDefault();
        if (formData.q1 !== 'yes' || formData.q2 !== 'yes' || formData.q3 !== 'yes') {
            return setFormData({
                ...formData,
                error: 'Due to your above response of ‘No’, you are unable to proceed with internet service payment using your OTC card.'
            });
        }
        if (formData.billerToken === OTHER_BILLER_NAME || formData.billerToken === '') {
            return setFormData({...formData, error: 'Biller is not selected'});
        }
        let state = {biller: {token: formData.billerToken, name: BillerNameByToken(formData.billerToken)}}
        navigate("/set_up_form", {state})
    }

    const handleChange = fieldName => event => {
        setFormData({
            ...formData,
            [fieldName]: event.target.value
        });
    };

    const handleSelectChange = fieldName => opt => {
        setFormData({
            ...formData,
            [fieldName]: opt.value
        });
    };

    const customStyles = {
        indicatorsContainer: () => ({
            '.myDropDown': {
                '&__dropdown-indicator': {
                    color: '#F1762E'
                }
            }
        })
    };

    const handleHideNotification = _ => event => {
        setPageState({
            ...pageState,
            hideDiscontinueNotify: true
        });
    };

    const defaultAttestation = () => {
        return (<div>
            <p>
                Select your Broadband provider from the drop-down list below to begin the payment process.
            </p>
            <hr className="account-separator mvm"/>
            <p className="bold"></p>

            <h4>Disclaimers</h4>
            <div>
                <ul>
                    <li>You can use your OTC Card benefits to pay for broadband or Utilities only for the service
                        providers
                        listed below. If your provider is not listed, please contact your health plan member services to
                        determine how to access this benefit.
                    </li>
                    <li>This bill pay site does not show your account balance with the selected service providers.
                        Please check your monthly statement from your service provider before making your monthly
                        payment.
                    </li>
                    <li>You can only pay up to the available balance on your OTC card at the time of payment. Any
                        additional payments should be processed to your service provider separately.
                    </li>
                    <li>Repeat payments are not currently available.</li>
                </ul>
            </div>
        </div>)
    };

    const discontinueNotification = () => {
        return (<div>
            <div className="alert-box">
                <h2>IMPORTANT:</h2>
                This OTC Plus Bill Pay site will no longer be available after June 30, 2024.<p/>
                We’ve made it easier for you to pay your utility bills, letting you use your OTC Plus card as you would
                a VISA card! Please start making these payments directly to your utility providers.<p/>
                Below are links to some of the most commonly used utility providers. If you don’t see yours, please look
                at your bills to learn what payment options are available to you. <p/>

                <a href='https://coned.com'>ConEdison</a><br/>
                <a href='https://nationalgridus.com'>National Grid</a><br/>
                <a href='https://optimum.net'>Altice/Optimum</a><br/>
                <a href='https://spectrum.net'>Spectrum</a><p/>
                You can still pay on this site until June 30th. To pay, <a href='#' onClick={handleHideNotification()}>click
                here</a>.
            </div>
        </div>)
    };

    //AKA attestation for HealthFirst
    const specialAttestation = () => {
        return (<div>
            {isHealthFirst && !pageState.hideDiscontinueNotify && discontinueNotification()}
            {isHealthFirst && pageState.hideDiscontinueNotify &&
                <div>
                    <h4>Pay Your Bill</h4>
                    <p>Select your home utility provider from the list below to begin the payment process.</p>
                    <p>Please answer the following questions attesting to your eligibility to pay your home utility
                        bill(s)
                        using your Over-The-Counter (OTC) Plus Card.</p>

                    <hr className="account-separator mvm"/>

                    <h4>Disclaimers</h4>
                    <div>
                        <ul>
                            <li>You may use your Healthfirst OTC Plus card home utility payment program to pay for your
                                internet, water, gas, and electric with our online payment portal. This site does not
                                show the
                                account balance you have with your home utility providers. Please check your monthly
                                account
                                balance with your home utility provider prior to making your monthly payment. Payments
                                will take
                                2-4 days to reflect on your utility account.
                            </li>
                        </ul>
                    </div>
                </div>
            }
        </div>)
    };

    const cantFindYourProvider = () => {
        return <div>
            <h4>Can't find your provider?</h4>
            Pay your bills as usual, then submit a reimbursement by clicking <a
            href="https://myhf.org/otc-plus-reim-form-eng">here</a> for the English reimbursement form, <a
            href="https://myhf.org/otc-plus-reim-form-esp">here</a> for Spanish, <a
            href="https://myhf.org/otc-plus-reim-form-chn">here</a> for Chinese. This may take approximately 10-14
            business days.
        </div>
    }

    return (
        <TwoColumnLayout>
            <h1>Pay Your Bill</h1>
            <div className="mobile-wrapper setup-new-biller-block">
                {isSpecialAttestations ? specialAttestation() : defaultAttestation()}
                {isHealthFirst && pageState.hideDiscontinueNotify &&
                    <div>
                        <form onSubmit={handleSubmit}>
                            <h4>By participating in this program, I attest that:</h4>
                            <table>
                                <tbody>
                                <tr className={classnames({'error-color shake': formData.error !== null && formData.q1 === 'no'})}>
                                    <td className='bold'>
                                        1. {isSpecialAttestations ? 'I am using my OTC card to pay for my home utility service.' : 'The Internet service is for my personal use.'}
                                    </td>
                                    <td onChange={handleChange('q1')}>
                                        <input type="radio" id="q1-yes" name="q1" value="yes"
                                               checked={formData.q1 === 'yes'}
                                               onChange={handleChange('q1')}/> <label htmlFor="q1-yes">Yes</label>&nbsp;
                                        <input type="radio" id="q1-no" name="q1" value="no"
                                               checked={formData.q1 === 'no'}
                                               onChange={handleChange('q1')}/> <label htmlFor="q1-no">No</label>
                                    </td>
                                </tr>
                                <tr className={classnames({'error-color shake': formData.error !== null && formData.q2 === 'no'})}>
                                    <td className='bold'>
                                        2. {isSpecialAttestations ? 'The home utility is for my home address only OR I live in the same address as the person whose name is on the home utility invoice.' : "The Internet service is for my home address only OR I live at the same address as the person whose name is on the Internet service invoice."}
                                    </td>
                                    <td onChange={handleChange('q2')}>
                                        <input type="radio" id="q2-yes" name="q2" value="yes"
                                               checked={formData.q2 === 'yes'}
                                               onChange={handleChange('q2')}/> <label htmlFor="q2-yes">Yes</label>&nbsp;
                                        <input type="radio" id="q2-no" name="q2" value="no"
                                               checked={formData.q2 === 'no'}
                                               onChange={handleChange('q2')}/> <label htmlFor="q2-no">No</label>
                                    </td>
                                </tr>
                                {isShowQ3 &&
                                    <tr className={classnames({'error-color shake': formData.error !== null && formData.q3 === 'no'})}>
                                        <td className='bold'>
                                            3. {isSpecialAttestations ?
                                            'The following question only applies to Healthfirst CompleteCare members: The home utilities help me to maintain my health.' :
                                            'CompleteCare Plan Member Question Only: The Internet service will be used for health-related services (i.e. telehealth communications, health-related apps, or other online resources or activities that may aid in the management of a chronic illness).'}
                                        </td>
                                        <td>
                                            <input type="radio" id="q3-yes" name="q3" value="yes"
                                                   checked={formData.q3 === 'yes'}
                                                   onChange={handleChange('q3')}/> <label
                                            htmlFor="q3-yes">Yes</label>&nbsp;
                                            <input type="radio" id="q3-no" name="q3" value="no"
                                                   checked={formData.q3 === 'no'}
                                                   onChange={handleChange('q3')}/> <label htmlFor="q3-no">No</label>
                                        </td>
                                    </tr>}
                                {formData.error &&
                                    <tr>
                                        <td colSpan="2" className="form-field-error-text">{formData.error}</td>
                                    </tr>}
                                </tbody>
                            </table>

                            <hr className="account-separator mvm"/>
                            <h2>Choose from approved providers:</h2>
                            <div className="search-container">
                                <div className="">
                                    <Select
                                        options={billers}
                                        placeholder='Please select a biller'
                                        menuPlacement='auto'
                                        onChange={handleSelectChange('billerToken')}
                                        styles={customStyles}
                                        classNamePrefix='myDropDown'
                                    />

                                    {formData.billerToken === OTHER_BILLER_NAME &&
                                        <div className="mtm">
                                            <a href='https://healthfirst.org/over-the-counter-otc-benefits/'>Open the
                                                Reimbursement form</a>
                                        </div>
                                    }
                                </div>

                                {formData.billerToken !== OTHER_BILLER_NAME && formData.billerToken !== '' &&
                                    <div className="mtm">
                                        <input type="submit" value="Search" className="btn-primary small"/>
                                    </div>
                                }

                            </div>
                        </form>

                        {isHealthFirst && <div>
                            <hr className="account-separator mvm"/>
                            {cantFindYourProvider()}
                        </div>
                        }
                    </div>
                }
            </div>
        </TwoColumnLayout>
    );
}
