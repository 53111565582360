import '../stylesheets/application.css';
import TwoColumnLayout from './TwoColumnLayout'
import {useNavigate, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {GetBillerForm, SendBillerForm} from "../api/BillerApi";
import {useAuth} from "../Auth";
import {useForm} from "react-hook-form";
import {AlternativeForToken} from "../api/local_db/AvailableBillers";

export default function SetUpFormPage() {
    let navigate = useNavigate();
    const location = useLocation();
    const {biller} = location.state || {biller: {name: 'biller should be selected on previous page', token: ''}};

    const [formStructure, setFormStructure] = useState([]);
    const [loaderVisible, setLoaderVisibility] = useState(false)
    const [pageError, setPageError] = useState()
    const defaultValues = location.state.formData || {}
    const {register, handleSubmit, getValues, formState: {errors}} = useForm({defaultValues});

    const auth = useAuth();
    const accessToken = auth.user?.accessToken
    const billerToken = biller.token

    useEffect(() => {
        setLoaderVisibility(true)
        GetBillerForm({accessToken, billerToken}
        ).then(data => {
            //rename field
            let i = data.data.biller.form.findIndex(field => field.label.toLowerCase() === 'account number')
            if (i !== -1) {
                data.data.biller.form[i].label = 'Utility Account Number (not Member ID)'
                data.data.biller.form[i].subLabel = 'Make sure to reference the account number on your printed utility bill statement.'
            }

            //add confirm field
            i = data.data.biller.form.findIndex(field => field.requires_confirmation !== undefined && field.requires_confirmation.toLowerCase() === 'true');
            if (i !== -1) {
                const oldField = data.data.biller.form[i];
                const newField = Object.assign({}, oldField);
                newField.confirmation = oldField.name;
                newField.label = `Confirm ${oldField.label}`;
                newField.subLabel = '';
                newField.name = `confirm_${oldField.name}`;
                data.data.biller.form.splice(i + 1, 0, newField);
            }

            setFormStructure(data.data.biller.form)
        }).catch(error => {
            setPageError(`Error: ${error.response?.data?.error?.message || error.message}`)
        }).finally(() => {
            setLoaderVisibility(false)
        });
    }, [accessToken, billerToken])

    const onSubmit = (formData) => {
        const confirmation = window.confirm('Please confirm you entered your utility account number and not member ID')
        if (confirmation === false) {
            return false
        }

        setLoaderVisibility(true)

        SendBillerForm({accessToken, billerToken, userData: formData})
            .then(response => {
                goConfirmation(formData, response.data.payment)
            })
            .catch((error) => {
                //send to alternative biller if it exists
                if (error.response?.status === 422 && error.response?.data?.error?.code === 601 && AlternativeForToken(billerToken)) {
                    let nextBillerToken = AlternativeForToken(billerToken)
                    SendBillerForm({accessToken, billerToken: nextBillerToken, userData: formData})
                        .then(response => {
                            biller['token'] = nextBillerToken
                            goConfirmation(formData, response.data.payment)
                        })
                        .catch(error => {
                            if (error.response.status === 422 && error.response?.data?.error?.code === 606) { // send to address selection if there is a list
                                biller['token'] = nextBillerToken
                                return goSelectAddress(formData, error.response.data.error.attributes.remittance_addresses)
                            }
                            setPageError(`Error: ${error.response?.data?.error?.message || error.message}. ${error.response?.data?.error?.attributes?.user_data}`)
                        }).finally(() => setLoaderVisibility(false))
                    return
                }
                if (error.response?.status === 422 && error.response?.data?.error?.code === 606) { // send to address selection if there is a list
                    return goSelectAddress(formData, error.response.data.error.attributes.remittance_addresses)
                }
                setPageError(`Error: ${error.response?.data?.error?.message || error.message}. ${error.response?.data?.error?.attributes?.user_data}`)
            }).finally(() => setLoaderVisibility(false))
    }

    const goSelectAddress = (formData, remittanceAddresses) => {
        navigate("/select_address", {state: {formData, biller, remittanceAddresses}})
    }

    const goConfirmation = (formData, paymentData) => {
        navigate("/confirm_an", {state: {formData, biller, payment: paymentData}})
    }

    const handleCancel = e => {
        e.preventDefault();
        navigate("/new_bill", {state: {biller}})
    }

    return (
        <TwoColumnLayout loaderVisible={loaderVisible}>
            <h1>{biller.name}</h1>
            {pageError && <div className='form-field-error-text'>{pageError}</div>}
            <hr/>
            <form className="form mobile-wrapper" onSubmit={handleSubmit(onSubmit)}>
                <div className="outer">
                    {formStructure.map(r => {
                        const validations = {
                            required: {value: r.required.toLowerCase() === 'true', message: "Can't be blank"},
                            maxLength: {value: r.max_len, message: `Maximal field length is ${r.max_len}`},
                            minLength: {value: r.min_len, message: `Minimal field length is ${r.min_len}`},
                            validate: {
                                confirmed: v => {
                                    if (r.confirmation === undefined) {
                                        return
                                    }
                                    if (getValues([r.confirmation])[0] !== v) {
                                        return "Doesn't match confirmation"
                                    }
                                },
                            }
                        }

                        return (
                            <div className="form-field mbm unit-2of3" key={r.name}>
                                <label htmlFor={r.name}>{r.label}</label>
                                {r.subLabel &&
                                    <label>{r.subLabel}</label>}
                                <input type="text" autoComplete="off" id={r.name} {...register(r.name, validations)}/>
                                {errors[r.name]?.message &&
                                    <div className="form-field-error-text">{errors[r.name].message}</div>}
                                {/*Show hint for ZIP field*/}
                                {r.label === 'Biller Remittance ZIP Code' &&
                                    <div className="help-tip confirmation">
                                        <p>You may find this on your bill statement. This is the biller's address for
                                            payments.</p>
                                    </div>}
                            </div>
                        )
                    })}
                </div>
                <div className="submit mtm">
                    <input type="button" className="btn-secondary mrm" value="Back" onClick={handleCancel}/>
                    <input type="submit" value="Submit" className="btn-primary"/>
                </div>
            </form>
        </TwoColumnLayout>
    );
}
