import '../stylesheets/application.css';
import TwoColumnLayout from './TwoColumnLayout'
import {Link} from "react-router-dom";

export default function BillPage() {
    return (
        <TwoColumnLayout>
            <h1>My Bills</h1>
            <h2>SPECTRUM - TIME WARNER **** **** **** 1951</h2>
            <div className="mobile-wrapper my-bills-page">
                <Link to="/my_bills/1">
                    <img src="http://screenshots.hive.dp.ua/2021-10/1633112779.png" alt="screenshot"/>
                </Link>
            </div>
        </TwoColumnLayout>
    );
}
