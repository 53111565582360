import '../stylesheets/application.css';
import {NavLink} from "react-router-dom";
import {AuthButton, useAuth} from "../Auth";
import {ELDERPLAN_PC, PC_TEST, PC_WITH_SPECIAL_ATTESTATION} from "../api/local_db/AvailableBillers";

function TwoColumnLayout(props) {
    const loaderVisible = props.loaderVisible
    const {user} = useAuth();
    const logoStyle = ELDERPLAN_PC.includes(user.programCode) ? 'subheader-logo-elderpla' : 'subheader-logo'
    const pp_url = PC_WITH_SPECIAL_ATTESTATION.includes(user.programCode) ? 'https://healthfirst.org/privacy-notices/' : 'https://www.incomm.com/about/policies/privacy/'
    return (
        <div>
            { loaderVisible && <section id="loader"/> }
            <header>
                <ul className="strict top"></ul>
            </header>

            <div className="subheader mobile-hide">
                <div className="strict">
                    <a className={logoStyle} href='/'> </a>
                    <div className='navigation'>
                    </div>
                </div>
            </div>

            <div className="main-content">
                <section className="strict">
                    <div className="content-box">

                        {user?.isAuthenticated && <SideNavigation/> }

                        <div className="right-column">
                            {user?.isAuthenticated && <MobileNavigation/> }
                            {/*Page content*/}
                            {props.children}

                        </div>
                    </div>
                </section>
            </div>

            <footer>
                <section className="strict">

                    <p>© {new Date().getFullYear()} InComm. All Rights Reserved.</p>

                    <p className="footer-links">
                        <a href={pp_url} target="_blank" rel="noreferrer">Privacy Policy</a>
                        <a href="https://media.cashtie.com/files/Website+Terms+of+Use+(12.17.21).pdf" target="_blank" rel="noreferrer">Terms of Use</a>
                        {process.env.REACT_APP_ENVIRONMENT !== 'production' && <AuthButton/>}
                    </p>
                </section>
            </footer>

        </div>
    );
}

function SideNavigation(){
    return (
        <div className="sidebar mobile-hide">
            <h3 className="sidebar-header">My Account</h3>
            <ul className="sidebar-list">
                <li><NavLink to="/my_bills" className={({ isActive }) => (isActive ? " active" : "")}>My Bills</NavLink></li>
                <li><NavLink to="/new_bill" className={({ isActive }) => (isActive ? " active" : "")}>Set up New Bill</NavLink></li>
            </ul>
        </div>
    )
}

function MobileNavigation(){
    return (
        <nav className="sidebar mobile-show">
            <ul className="sidebar-list">
                <li><NavLink to="/my_bills" className={({ isActive }) => (isActive ? " active" : "")}>My Bills</NavLink></li>
                <li><NavLink to="/new_bill" className={({ isActive }) => (isActive ? " active" : "")}>Set up New Bill</NavLink></li>
            </ul>
        </nav>
    )
}

export default TwoColumnLayout;
