import axios from "axios";

export function GetBillerForm({accessToken, billerToken}) {
    const options = { headers: headers(accessToken) }
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/billers/${billerToken}`, options)
}

export function SendBillerForm({accessToken, billerToken, userData, userSelectedAddress}) {
    const options = { headers: headers(accessToken) }
    const body = {
        payment: {
            created_in: 'otc',
            user_data: userData
        }
    }
    if (userSelectedAddress !== undefined) { body.payment.user_selected_address = userSelectedAddress }
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/billers/${billerToken}/payments`, body, options)
}

export function Confirm({accessToken, billerToken, paymentToken}) {
    const options = { headers: headers(accessToken) }
    const body = {payment: {}}
    return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/billers/${billerToken}/payments/${paymentToken}/confirm`, body, options)
}

export function Amountize({accessToken, billerToken, paymentToken, amount}) {
    const options = { headers: headers(accessToken) }
    const body = {payment: { amount: amount } }
    return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/billers/${billerToken}/payments/${paymentToken}/amountize`, body, options)
}

export function GetBalance({accessToken, paymentToken}) {
    const options = { headers: headers(accessToken) }
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/otc/payment/${paymentToken}/balance`, options)
}

export function PostPayment({accessToken, paymentToken}) {
    const options = { headers: headers(accessToken) }
    const body = {}
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/otc/payment/${paymentToken}/post`, body, options)
}

export function Payments({accessToken}) {
    const options = { headers: headers(accessToken) }
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/biller_accounts?s=otc`, options)
}

function headers(accessToken){
    return {
        'X-Access-Token': accessToken,
        'X-BRANDED-FOR': 'pih',
        'X-CLIENT-APP': 'otc',
        'X-CURRENT-RETAILER': process.env.REACT_APP_RETAILER_TOKEN,
    }
}
