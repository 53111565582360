import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import NewBillerPage from './pages/NewBillerPage';
import SetUpFormPage from './pages/SetUpFormPage';
import MyBillsPage from './pages/MyBillsPage';
import NotFoundPage from './pages/NotFoundPage';
import reportWebVitals from './reportWebVitals';
import ConfirmNumberPage from "./pages/ConfirmNumberPage";
import AmountizePage from "./pages/AmountizePage";
import ThankYouPage from "./pages/ThankYouPage";
import BillPage from "./pages/BillPage";
import {PrivateRoute, ProvideAuth} from "./Auth";
import NoAuthPage from "./pages/NoAuthPage";
import SelectAddressPage from "./pages/SelectAddressPage";
import SignInPage from "./pages/SignInPage";

ReactDOM.render(
    <React.StrictMode>
        <ProvideAuth>
            <Router>
                <Routes>
                    <Route exact path="/" element={<Navigate to="/new_bill"/>} />
                    <Route exact path="/no_auth" element={<NoAuthPage/>} />
                    <Route exact path="/sign_in" element={<SignInPage/>} />
                    <Route exact path="/my_bills" element={<PrivateRoute/>}> <Route exact path="/my_bills" element={<MyBillsPage/>}/> </Route>
                    <Route exact path="/my_bills/:id" element={<PrivateRoute/>}> <Route exact path="/my_bills/:id" element={<BillPage/>}/> </Route>
                    <Route exact path="/new_bill" element={<PrivateRoute/>}> <Route exact path="/new_bill" element={<NewBillerPage/>}/> </Route>
                    <Route exact path="/set_up_form" element={<PrivateRoute/>}> <Route exact path="/set_up_form" element={<SetUpFormPage/>}/> </Route>
                    <Route exact path="/select_address" element={<PrivateRoute/>}> <Route exact path="/select_address" element={<SelectAddressPage/>}/> </Route>
                    <Route exact path="/confirm_an" element={<PrivateRoute/>}> <Route exact path="/confirm_an" element={<ConfirmNumberPage/>}/> </Route>
                    <Route exact path="/amountize" element={<PrivateRoute/>}> <Route exact path="/amountize" element={<AmountizePage/>}/> </Route>
                    <Route exact path="/thank_you" element={<PrivateRoute/>}> <Route exact path="/thank_you" element={<ThankYouPage/>}/> </Route>
                    <Route path="*" element={<NotFoundPage/>} />
                </Routes>
            </Router>
        </ProvideAuth>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
