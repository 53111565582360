export function urlSafeBase64Decode(encoded) {
    encoded = encoded.replace('-', '+').replace('_', '/');
    while (encoded.length % 4)
        encoded += '=';
    return new Buffer(encoded || '', 'base64').toString('utf8');
}

export function maskedString(str, startUnmasked = 0, endUnmasked = 4) {
    const len = str.length
    const asteriskCount = Math.max(len - startUnmasked - endUnmasked, 0)
    return str.substring(0, startUnmasked) + "*".repeat(asteriskCount) + str.substring(len - endUnmasked, len)
}
