// To obtain data run in Rails console:
// b = Biller.where(name: '', payment_flow_class: 'PaymentFlow::ACI')
// b = Biller.where('name ilike ?', 'VERIZON PREPAID')
// Biller.where(name: 'LONG ISLAND LIGHTING COMPANY', payment_flow_class: 'PaymentFlow::ACI')[0].update(item_code: '00016800041468', token: 'a14e86163069ada24f3d8b44977d36aa')

export const ALLOWED_PROGRAM_CODES = [
    "LIP65+",
    "P29-4242",
    "P29-3739",
    "PS6-10236",
    "PS6-10234",
    "PS6-10235",
    "PS6-10237",
    "PM8-4265",
    "PL9-4212",
    "PW0-4637",
    "PW0-4677",
    "PW0-4649",
    "PW0-4639", //test, CAS-4795
    "PM8-4274", //test, CAS-4795
    "PW0-4733", //test, CAS-4795
    "PL9-4581",
]

// every program codes not from this list considered as HealthFirst codes
export const ELDERPLAN_PC = [
    "PM8-4265",
    "PL9-4212",
    "PM8-4274"
]
export const PC_WITH_3_QUESTION = [
    'P29-3739',
    'PS6-10235',
    'PW0-4639',
    'PW0-4637',
    'PW0-4733',
    'PL9-4581',
]
export const PC_WITH_SPECIAL_ATTESTATION = [
    "LIP65+",
    "P29-4242",
    "P29-3739",
    "PS6-10236",
    "PS6-10234",
    "PS6-10235",
    "PS6-10237",
    'PW0-4639',
    'PW0-4637',
    'PW0-4733',
    'PL9-4581',
]
export const PARTNER_HEALTH_FIRST = "HF"
export const PARTNER_ELDERPLAN = "EP"
const PC_ALL = ["All"]
export const PC_TEST = ["PW0-4639", "PM8-4274", 'PW0-4637', 'PW0-4733']

let AVAILABLE_BILLERS;
let ALTERNATIVE_TOKENS;
const ALL_HP = [PARTNER_HEALTH_FIRST, PARTNER_ELDERPLAN]
const ONLY_EP = [PARTNER_ELDERPLAN]
const ONLY_HF = [PARTNER_HEALTH_FIRST]

switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'development':
    case 'qa':
    case 'production':
        AVAILABLE_BILLERS = [
            ["2f24948d192aae9b345a7f22a176a984", "AT&T INTERNET", ["Internet"], ALL_HP, PC_ALL],
            ["8cd89521417c52916833e149a3f31d99", "COMCAST CABLE - XFINITY", ["Internet"], ALL_HP, PC_ALL],
            ["797a754edaddf9eb01de3afc74c37625", "CONSOLIDATED COMMUNICATIONS", ["Internet"], ALL_HP, PC_ALL],
            ["0ce5f6ee6cb7e685f76a3a27a8f7497e", "EARTHLINK", ["Internet"], ALL_HP, PC_ALL],
            ["3855d3add0c36a259665df9cbf31b72b", "FRONTIER", ["Internet"], ALL_HP, PC_ALL],
            ["388218136edad46d1e9e512b84b34572", "HUGHES NETWORK SYSTEMS LLC", ["Internet"], ALL_HP, PC_ALL],
            ["664f566a1c95a1e813e8efb03bcd8114", "OPTIMUM/ALTICE", ["Internet"], ONLY_HF, PC_ALL],
            ["f167545bab2c8332f746d5475428aa57", "RCN", ["Internet"], ALL_HP, PC_ALL],
            ["558f21679983306be94b10f1100c85ce", "SPECTRUM - TIME WARNER", ["Internet"], ALL_HP, PC_ALL],
            ["14ac421511f07acf452897367c856434", "VERIZON", ["Internet"], ALL_HP, PC_ALL],

            ["018da55dc4240d1b14282695f87bbb8c", "CENTRAL HUDSON GAS AND ELECTRIC", ["Gas", "Electric"], ALL_HP, ['PW0-4639', 'PW0-4637', 'PW0-4733', 'PM8-4274', 'PM8-4265', 'PL9-4581' ]],
            ["2a3242bad5e564ca7f20861c6b367d69", "CONSOLIDATED EDISON", ["Gas", "Electric"], ALL_HP, ['PW0-4639', 'PW0-4637', 'PW0-4733', 'PM8-4274', 'PM8-4265', 'PL9-4581']],
            ["bd7246ecc38ccb3db4b6fa1a2ffb34fb", "LIBERTY UTILITIES", ["Water"], ALL_HP, ['PW0-4639', 'PW0-4637', 'PW0-4733', 'PM8-4274', 'PM8-4265', 'PL9-4581']],
            ["c295e658201924c4ebc5474b22d8ef1a", "NATL GRID/KEYSPAN ENERGY", ["Gas"], ALL_HP, ['PW0-4639', 'PW0-4637', 'PW0-4733', 'PM8-4274', 'PM8-4265', 'PL9-4581']],
            ["041842e30432a843f6d8959e53983f44", "NEW YORK CITY WATER BOARD", ["Water"], ALL_HP, ['PW0-4639', 'PW0-4637', 'PW0-4733', 'PM8-4274', 'PM8-4265', 'PL9-4581']],
            ["e3564a34206453f1ab8dad6366d6b768", "NEW YORK STATE ELECTRIC AND GAS", ["Gas", "Electric"], ALL_HP, ['PW0-4639', 'PW0-4637', 'PW0-4733', 'PM8-4274', 'PM8-4265', 'PL9-4581']],
            ["4459c100d9c776afeef7231b6452d324", "ORANGE & ROCKLAND UTILITIES", ["Gas", "Electric"], ALL_HP, ['PW0-4639', 'PW0-4637', 'PW0-4733', 'PM8-4274', 'PM8-4265', 'PL9-4581']],
            ["d8b967c5931ede91a5c95bf0831021ad", "UNITED WATER MANAGEMENT", ["Water"], ALL_HP, ['PW0-4639', 'PW0-4637', 'PW0-4733', 'PM8-4274', 'PM8-4265', 'PL9-4581']],
            ["6eddef1e3842baaaf61e742c1435baab", "VILLAGE OF FREEPORT NY ELECTRIC", ["Utility", "Gas", "Electric"], ALL_HP, ['PW0-4639', 'PW0-4637', 'PW0-4733', 'PM8-4274', 'PM8-4265', 'PL9-4581']],
            ["ec98f16eb09599d272ab26bff4ed30a4", "VILLAGE OF ROCKVILLE CENTRE NY", ["Utility", "Gas", "Electric"], ALL_HP, ['PW0-4639', 'PW0-4637', 'PW0-4733', 'PM8-4274', 'PM8-4265', 'PL9-4581']],
            ["8217166688d130dcf3f4a40c79adb0d0", "AMERICAN MESSAGING SERVICE LLC", ["Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["faada40c861b0a43cfacdc05754205d0", "APPALACHIAN WIRELESS", ["Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["d05937829baa5a13fba1e786e4107a7b", "AT&T", ["Phone", "Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["ed322b43bcda1107dc47916830d320a8", "ATLANTIC BROADBAND", ["Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["bfaad2e6e8fa0cb7ebf0f751a1ebd91f", "BLUEGRASS CELLULAR", ["Phone", "Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["d3839986cb46e6e348795907475d6d00", "BRAZOS COMPANIES", ["Phone", "Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["da65179c2961a6fde36cb24dbb4abf7b", "C SPIRE WIRELESS/CELLULAR SOUTH", ["Phone", "Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["19cac0458bd757459e96f01411c2a1e8", "CAROLINA WEST WIRELESS", ["Phone", "Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["c6092de8aab9635e087b0b25cf9c54b4", "CELLULAR ONE", ["Phone", "Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["24edc19374afcbd9e1e97c11c0835d45", "CELLULAR ONE OF NE ARIZONA", ["Phone", "Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["1c882aaa1b2868d7d0d9b49237ee9518", "GENERAL COMMUNICATIONS INC", ["Phone", "Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["1f2a623ff563b6ecafb85c1e71620172", "GUADALUPE VALLEY TELECOM COOP", ["Phone", "Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["2332483d358b102c515828d7e48f4f8c", "INLAND CELLULAR", ["Phone", "Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["6d30350e3a886aef3ed9ca87479f4916", "ITC AKA INTERSTATE TELECOM", ["Phone", "Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["7634c7b25e39b5dd34f126b1b4e9c329", "MATANUSKA TELEPHONE ASSN", ["Phone", "Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["c39f9ff45f96ebadb59bfa2a271aaa2b", "NEXTEL COMMUNICATIONS", ["Phone", "Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["063c977cb4be02682cc1d9424f51194b", "NORTHWEST MISSOURI CELLULAR", ["Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["4dd1fb9af7d387417fbf4d2018978f57", "NSIGHTTEL WIRELESS", ["Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["297e21102982fbe961e0077c9a147fa1", "SEI COMMUNICATIONS", ["Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["e3249d8d44e65c0b95d7b1b7ffec89b5", "SUNCOM", ["Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["67c93fedd56e85c52efb4e219e082d99", "T-MOBILE", ["Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["4d2ef1d207b6333ea9c4889d8d6597a2", "US CELLULAR", ["Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["5ec46d9d78d99b7c6480d21ec508182c", "VALU NET", ["Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["8ebb8257fbdcad78f46b4cb0498cb627", "VERIZON WIRELESS", ["Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["9dca986f23b6d7f3be86b27f501cdd88", "VIAERO WIRELESS", ["Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["5d12b0c03fd35c8e7015e2b16aff768b", "WINDSTREAM PAETEC", ["Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["41aa0b7b3e08428b824417d365de2f5c", "WIRELESS BEEHIVE", ["Wireless"], ONLY_EP, ['PM8-4274', 'PM8-4265']],
            ["a14e86163069ada24f3d8b44977d36aa", "LONG ISLAND LIGHTING COMPANY", ["Electric"], ALL_HP, PC_ALL],
        ];

        ALTERNATIVE_TOKENS = {
            '558f21679983306be94b10f1100c85ce': "4422e8beceda7e40f51048697ad17542", //SPECTRUM - TIME WARNER <-> SPECTRUM-FORMERLY TIME WARNER CABLE NYC
            'f167545bab2c8332f746d5475428aa57': "38c9598a3dd907e232aebfaa00d859e8", //RCN <-> RCN ICOMS
        }
        break;
    default:
        throw new Error(`There is no biller list for env: ${process.env.REACT_APP_ENVIRONMENT}`)
}
// wireless billers for testing on QA
if (process.env.REACT_APP_ENVIRONMENT === 'qa') {
    AVAILABLE_BILLERS.push(
        ["96d08fbea74b57b38b753f9b1605935c", "Verizon Prepaid", ["Phone"], ALL_HP, PC_ALL],
        ["67c93fedd56e85c52efb4e219e082d99", "T-MOBILE SIMPLY PREPAID™", ["Phone"], ALL_HP, PC_ALL],
        ["82c541b51b2c2c4f635794529b41e6f9", "AT&T Prepaid℠", ["Phone"], ALL_HP, PC_ALL],
    )
}

export function BillerNameByToken(token) {
    return (AVAILABLE_BILLERS.find(([tkn, _]) => tkn === token) || [])[1]
}

export function AlternativeForToken(token) {
    return ALTERNATIVE_TOKENS[token] || null
}

export function GroupedBillersFor(programCode, healthPlanCode) {
    console.log('programCode', programCode, 'healthPlanCode', healthPlanCode);
    const TOKEN = 0
    const NAME = 1
    const CATEGORIES = 2
    const FOR_PARTNERS = 3
    const FOR_PC = 4
    let partner = (ELDERPLAN_PC.includes(programCode)) ? PARTNER_ELDERPLAN : PARTNER_HEALTH_FIRST
    let groupedBillers = []

    AVAILABLE_BILLERS.forEach(line => {
        if (!line[FOR_PARTNERS].includes(partner)) {
            return; // just skip the biller
        }
        if (!(line[FOR_PC] === PC_ALL || line[FOR_PC].includes(programCode))) {
            return; // skip the biller
        }

        const option = {value: line[TOKEN], label: line[NAME]}
        line[CATEGORIES].forEach(categoryName => {
            let i = groupedBillers.findIndex(group => group.label === categoryName)
            if (i === -1) {
                groupedBillers.push({label: categoryName, options: [option]})
            } else {
                groupedBillers[i]['options'].push(option)
            }
        })
    })

    return groupedBillers
}
