import '../stylesheets/application.css';
import TwoColumnLayout from './TwoColumnLayout'
import {useNavigate, useLocation} from "react-router-dom";
import {useState} from "react";
import {Confirm} from "../api/BillerApi";
import {useAuth} from "../Auth";

export default function ConfirmNumberPage() {
    let navigate = useNavigate();
    const location = useLocation();
    const {formData, biller, payment} = location.state || {
        formData: {},
        biller: {name: '<biller is not set>', token: 'n/a'}
    };
    const [loaderVisible, setLoaderVisibility] = useState(false)
    const {user} = useAuth();
    const accessToken = user?.accessToken
    const [pageError, setPageError] = useState()

    const handleSubmit = e => {
        e.preventDefault();
        setLoaderVisibility(true)
        Confirm({accessToken, billerToken: biller.token, paymentToken: payment.token}
        ).then(response => {
            navigate("/amountize", {state: {formData, biller, payment}, replace: true})
        }).catch((error) => {
            setPageError(`Error: ${error.response?.data?.error?.message || error.message}`)
        }).finally(() => {
            setLoaderVisibility(false)
        })
    }

    const handleCancel = e => {
        e.preventDefault();
        navigate("/set_up_form", {state: {formData, biller}})
    }

    return (
        <TwoColumnLayout loaderVisible={loaderVisible}>
            {pageError && <div className='form-field-error-text'>{pageError}</div>}
            <h1>Is this your account in {biller.name}?</h1>

            <div className="mbm verification-info">
                <div className="unit-1of1 mobile-text-center">
                    <div className="info-label">Billing Account Number</div>
                    <div className="info-value">
                        {formData.account_number}
                    </div>
                </div>
                {formData.full_name &&
                    <div className="unit-1of1 mobile-text-center">
                        <div className="info-label">Full Name</div>
                        <div className="info-value">
                            {formData.full_name}
                        </div>
                    </div>
                }
                {payment.validation_label &&
                    <div className="unit-1of1 mobile-text-center" style={{position: 'relative'}}>
                        <div className="info-label">{payment.validation_label}</div>
                        <div className="help-tip confirmation">
                            <p>You may find this on your bill statement. This is the biller's address for payments.</p>
                        </div>
                        <div className="info-value">
                            {payment.validation_value}
                        </div>
                    </div>
                }
            </div>

            <div className="mobile-wrapper">
                <div className="submit mtm">
                    <input type="button" className="btn-secondary" value="No, it is not my account"
                           onClick={handleCancel}/>
                    <input type="button" className="btn-primary mlm" value="Yes, this is my account"
                           onClick={handleSubmit}/>
                </div>
            </div>
        </TwoColumnLayout>
    );
}
