/**
 * https://reactrouter.com/web/example/auth-workflow
 * For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
import React, {useContext, createContext, useState} from "react";
import jwt_decode from "jwt-decode";
import {
    Navigate,
    useNavigate,
    useLocation,
    Outlet
} from "react-router-dom";
import axios from "axios";
import {ALLOWED_PROGRAM_CODES} from "./api/local_db/AvailableBillers";

const JWT_COOKIE_NAME = 'sso-jwt'

const AuthApi = {
    getUserObjFromSessionStorage() {
        const encoded_jwt = window.sessionStorage.getItem(JWT_COOKIE_NAME);
        let jwt = {}
        try {
            jwt = jwt_decode(encoded_jwt);
        } catch (InvalidTokenError) { }
        const isNotExpired = jwt.exp > Date.now() / 1000
        const accessToken = isNotExpired ? jwt.access_token : null
        return {
            accessToken: accessToken,
            healthPlanCode: jwt.health_plan_code,
            programCode: jwt.program_code,
            cardNumber: jwt.otc_card_number,
            isAuthenticated: accessToken !== null,
        }
    },
    sign_in(cb) {
        cb(AuthApi.getUserObjFromSessionStorage())
    },
    sign_out(cb) {
        window.sessionStorage.removeItem(JWT_COOKIE_NAME)
        const userObj = {
            accessToken: null,
            healthPlanCode: null,
            programCode: null,
            isAuthenticated: false,
        }
        cb(userObj)
    }
};

const authContext = createContext();

export function ProvideAuth({children}) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

export function useAuth() {
    return useContext(authContext);
}

function useProvideAuth() {
    const [user, setUser] = useState(AuthApi.getUserObjFromSessionStorage());

    const sign_in = cb => {
        return AuthApi.sign_in((userObj) => {
            setUser(userObj);
            cb();
        });
    };

    const sign_out = cb => {
        return AuthApi.sign_out((userObj) => {
            setUser(userObj);
            cb();
        });
    };

    // setup auto redirect to /no_auth if API returns 401 Unauthorized
    const onRejectInterception = (error) => {
        if (error.response.status === 401) {
            sign_out(() => console.debug('Session expired'))
        }
        return Promise.reject(error);
    }
    axios.interceptors.response.use((response) => { return response }, onRejectInterception);

    return {
        user,
        sign_in,
        sign_out
    };
}

export function AuthButton() {
    let navigate = useNavigate();
    let auth = useAuth();
    const url = `/emulator.html?backend_url=${encodeURIComponent(process.env.REACT_APP_BACKEND_URL)}`

    return auth.user?.isAuthenticated ? (
        <button onClick={() => { auth.sign_out(() => navigate("/")) }}>Sign out</button>
    ) : (
        <a href={url}>Emulator</a>
    );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function PrivateRoute() {
    const auth = useAuth();
    let location = useLocation();
    const isAllowed = auth.user?.isAuthenticated && ALLOWED_PROGRAM_CODES.includes(auth.user?.programCode)
    return isAllowed ? <Outlet/> : <Navigate to={{pathname: "/no_auth", state: {from: location}}}/>;
}
